.loading-screen{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    background: #191919;
    background: white;
    height: 100vh;
    width: 100vw;
    animation-duration: 0.2s;
    animation-delay: 2.6s;
  }
  
  .loading-screen svg {
    max-width: 400px;
  }
  
  .loading-screen.start {
    animation-name: start;
    opacity: 1;
  }
  
  .loading-screen.remove {
    opacity: 0;
    display: none !important;
  }
  
  @keyframes start {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        display: none !important;
      }
    }
  