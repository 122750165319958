.fut-wrapper {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
}

/*
    *** fut-player-card styles ***
*/
.fut-player-card {
    position: relative;
    /* width: 210px;
    height: 280px; */


    /* background-image: url(https://selimdoyranli.com/cdn/fut-player-card/img/card_bg.png); */

    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* padding: 3.8rem 0; */
    z-index: 2;
    -webkit-transition: 200ms ease-in;
    transition: 200ms ease-in;
}

/* default */
.fut-card{
    background-image: url('../assets/fifa-card-backgrounds/fut_card.png');
    width: 180px;
    height: 270px;
}

.fut-card-info,.fut-card-top{
    color: white
}

/* headlines */
.fut-headlines{
    background-image: url('../assets/fifa-card-backgrounds/fut_headlines.png');
    width: 180px;
    height: 270px;
}

.fut-headlines-info,.fut-headlines-top{
    color: #e9e602
}

/* hero */
.fut-hero{
    background-image: url('../assets/fifa-card-backgrounds/fut_hero.png');
    width: 180px;
    height: 270px;
}

.fut-hero-info,.fut-hero-top{
    color: #f9e402 !important;
}

/* icons */
.fut-icons{
    background-image: url('../assets/fifa-card-backgrounds/fut_icons.png');
    width: 180px;
    height: 270px;
}

.fut-icons-info,.fut-icons-top{
    color: #625218 !important;
}

/* ones to watch */
.fut-ones-to-watch{
    background-image: url('../assets/fifa-card-backgrounds/fut_ones_to_watch.png');
    width: 180px;
    height: 270px;
}

.fut-ones-to-watch-info,.fut-ones-to-watch-top{
    color: #fc5462 !important;
}

/* shapeshifters */
.fut-shapeshifters{
    background-image: url('../assets/fifa-card-backgrounds/fut_shapeshifters.png');
    width: 180px;
    height: 270px;
}

.fut-shapeshifters-info,.fut-shapeshifters-top{
    color: #fafafa !important;
}

/* summer heat */
.fut-summer-heat{
    background-image: url('../assets/fifa-card-backgrounds/fut_summer_heat.png');
    width: 180px;
    height: 270px;
}

.fut-summer-heat-info,.fut-summer-heat-top{
    color: #cf0335 !important;
    margin-top: 5%;
}

/* champions league */
.fut-cl{
    background-image: url('../assets/fifa-card-backgrounds/fut_cl.png');
    width: 210px;
    height: 270px;
}

.fut-cl-info,.fut-cl-top{
    color: white !important;
    margin-top: 5%;
}

.fut-player-card.fut-cl .player-master-info{
    margin-left: 8px !important;
}

/* acid */
.fut-acid{
    background-image: url('../assets/fifa-card-backgrounds/fut_acid.png');
    width: 210px;
    height: 270px;
}

.fut-acid-info,.fut-acid-top{
    color: #b9f328 !important;
    margin-top: 5%;
}

.fut-player-card.fut-acid .player-master-info{
    margin-left: 8px !important;
}

/* gold */
.fut-gold{
    background-image: url('../assets/fifa-card-backgrounds/fut_gold.png');
    width: 210px;
    height: 270px !important;
}

.fut-gold-info,.fut-gold-top{
    color: white !important;
    margin-top: 5%;
}

.fut-player-card.fut-gold .player-master-info{
    margin-left: 8px !important;
}

/* nike */
.fut-nike{
    background-image: url('../assets/fifa-card-backgrounds/fut_nike.png');
    width: 210px;
    height: 270px;
}

.fut-nike-info,.fut-nike-top{
    color: white !important;
    margin-top: 5%;
}

.fut-player-card.fut-nike .player-master-info{
    margin-left: 8px !important;
}

/* subamericana */
.fut-subamericana{
    background-image: url('../assets/fifa-card-backgrounds/fut_subamericana.png');
    width: 180px;
    height: 270px;
}

.fut-subamericana-info,.fut-subamericana-top{
    color: white !important;
    margin-top: 5%;
}

.fut-player-card.fut-subamericana .player-master-info{
    /* margin-left: 8px !important; */
}

/* tots */
.fut-tots{
    background-image: url('../assets/fifa-card-backgrounds/fut_tots.png');
    width: 180px;
    height: 270px;
}

.fut-tots-info,.fut-tots-top{
    color: #eed170 !important;
}

.fut-player-card {
    height: 285px;
}

.fut-player-card .player-card-top {
    position: relative;
    display: -webkit-box;
    display: flex;
    color: #e9cc74;
    padding: 0 0.9rem;
}

.fut-player-card .player-card-top .player-master-info {
    position: absolute;
    line-height: 1.5rem;
    font-weight: 300;
    padding: 2.5rem 0.4rem;
    /* z-index: 1000; */
    text-transform: uppercase;

    /* background-image: linear-gradient(180deg,rgba(255,0,0,0),white ,white,white,transparent); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px;
    margin-top: 23px;
    padding: 6px;
}

.master-info-dark{
    /* background-image: linear-gradient(180deg,rgba(255,0,0,0),#1d1d1d ,#1d1d1d,#1d1d1d,transparent); */
    background-image: linear-gradient(180deg,rgba(255,0,0,0),#00000052,#1d1d1d,#00000052,transparent);
}

.master-info-light{
    /* background-image: linear-gradient(180deg,rgba(255,0,0,0),white ,white,white,transparent); */
    background-image: linear-gradient(180deg,rgba(255,0,0,0),#ffffff59,#fff,#ffffff59,transparent);
    background-image: linear-gradient(180deg,rgba(255,0,0,0),#00000052,#1d1d1d,#00000052,transparent);
}

.fut-player-card .player-card-top .player-master-info .player-rating {
    font-size: 2rem;
}
.fut-player-card .player-card-top .player-master-info .player-position {
    font-size: 1.0rem;
}
.fut-player-card .player-card-top .player-master-info .player-nation {
    display: block;
    width: 2rem;
    height: 25px;
    margin: 0.0rem 0;
}

.fut-player-card .player-card-top .player-master-info .player-nation img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.fut-player-card .player-card-top .player-master-info .player-club {
    display: block;
    width: 2.1rem;
    height: 40px;
}
.fut-player-card .player-card-top .player-master-info .player-club img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.fut-player-card .player-card-top .player-picture {
    width: 200px;
    height: 150px;
    margin: 0 auto;
    position: relative;
}

.fut-player-card .player-card-top .player-picture img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    position: relative;
    right: -1.2rem;
    top: 1.3rem;
    bottom: 0rem;
}

.fut-player-card .player-card-top .player-picture img {
    -webkit-mask-image: linear-gradient(to bottom, black 0%, black 85%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 0%, black 85%, transparent 100%);
}

.fut-player-card .player-card-bottom {
    position: relative;
}
.fut-player-card .player-card-bottom .player-info {
    display: block;
    padding: 0.0rem 0;
    color: #e9cc74;
    width: 90%;
    margin: 0 auto;
    height: auto;
    position: relative;
    z-index: 2;
}
.fut-player-card .player-card-bottom .player-info .player-name {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 1.6rem;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(233, 204, 116, 0.1);
    padding-bottom: 0.0rem;
    padding-top: 0;

    overflow: hidden;
}
.fut-player-card .player-card-bottom .player-info .player-name span {
    display: block;
    /* text-shadow: 2px 2px #111; */
}
.fut-player-card .player-card-bottom .player-info .player-features {
    margin: 0rem auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col {
    border-right: 2px solid rgba(233, 204, 116, 0.1);
    padding: 0 1.1rem;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col span {
    display: -webkit-box;
    display: flex;
    font-size: 0.8rem;
    text-transform: uppercase;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-value {
    margin-right: 0.3rem;
    font-weight: 700;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-title {
    font-weight: 300;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col:last-child {
    border: 0;
}

