@keyframes pulse {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.pulse-circle {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-left: 6px;
    animation: pulse 2s infinite;
}
