.swiper-pagination-bullet-active {
    background-color: white !important;
}
.black .swiper-pagination-bullet-active {
    background-color: black !important;
}

/* Style for the progress bar container */
.swiper-pagination-progressbar {
    background-color: #e0e0e0; /* Background color of the progress bar track */
}

/* Style for the progress bar fill */
.swiper-pagination-progressbar-fill {
    background-color: black !important; /* Color of the filled part of the progress bar */
}